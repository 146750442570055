import * as React from 'react';

import { PageBase, PageBaseProps } from '../PageBase/PageBase';
import { Helmet } from 'react-helmet';

interface Props extends PageBaseProps {}
export interface PageSingleProps extends PageBaseProps {}

export class PageSingle extends React.Component<Props> {
    render() {
        const { title: pageTitle, children } = this.props;

        return <PageBase title={pageTitle}>{children}</PageBase>;
    }
}
