import * as React from 'react';

import styles from './Siteheader.module.scss';

export class Siteheader extends React.Component {
    render() {
        return (
            <div className={styles.container}>
                <header className={styles.header}>
                    <h1>Stef van Dijk - Blog</h1>
                </header>
                <nav className={styles.nav}>
                    <ul>
                        <li>
                            <a href='/'>Home</a>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }
}
