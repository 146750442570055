import * as React from 'react';
import { graphql } from 'gatsby';
import { MarkdownRemarkConnection } from '../../graphql-types';

import { PageSingle } from '../components/layouts/PageSingle/PageSingle';

interface Props {
    data: {
        site: {
            siteMetadata: {
                title: string;
            };
        };
        allMarkdownRemark: MarkdownRemarkConnection;
    };
}

interface State {
    // font: string;
}

export default class extends React.Component<Props, State> {
    constructor(props: Props, context: any) {
        super(props, context);
    }

    public render() {
        return (
            <PageSingle title={'Home'}>
                <p>
                    Welcome to{' '}
                    <strong>{this.props.data.site.siteMetadata.title}</strong>.
                </p>
                <ul>
                    {this.props.data.allMarkdownRemark.edges.map(
                        (entry: any, i: number) => {
                            const article = entry.node;
                            const { title, slug } = article.frontmatter;

                            return (
                                <li key={i}>
                                    <a href={`/blog/${slug}`}>{title}</a>
                                </li>
                            );
                        },
                    )}
                </ul>
                {/* <pre>
                    {JSON.stringify(
                        this.props.data.allMarkdownRemark.edges,
                        null,
                        4,
                    )}
                </pre> */}
            </PageSingle>
        );
    }
}

export const pageQuery = graphql`
    query IndexQuery {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        slug
                        date
                        dateFrom: date(fromNow: true)
                    }
                }
            }
        }
    }
`;
