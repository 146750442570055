import * as React from 'react';
import { Helmet } from 'react-helmet';

import '../../../variables.scss';
import styles from './PageBase.module.scss';
import { config } from '../../../config';
import { Siteheader } from '../../Siteheader/Siteheader';

interface Props {
    title: string;
}
export interface PageBaseProps extends Props {}

export class PageBase extends React.Component<Props> {
    render() {
        const { title, children } = this.props;

        return (
            <div className={styles.container}>
                <Helmet>
                    <title>{`${config.siteTitle} ${config.titleSeperator} ${title}`}</title>
                </Helmet>
                <Siteheader />
                <main className={styles.main}>
                    <header className={styles.mainheader}>
                        <h1>{this.props.title}</h1>
                    </header>
                    {children}
                </main>
            </div>
        );
    }
}
